/* ==============
  Bootstrap-custom
===================*/

// Breacrumb
.breadcrumb {
  background-color: transparent;
  margin-bottom: 15px;
  margin-top: 5px;
}

//Dropdown
.dropdown-menu {
  padding: 4px 0;
  font-size: 15px;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.08);
  border-color: $light;
}

.dropdown-item.active, .dropdown-item:active {
  color: $dark;
  text-decoration: none;
  background-color: $light;
}

.dropdown-item {
  padding: 6px 1.5rem;
}

.dropdown-menu-lg {
  width: 270px;
}


//Background color
.bg-primary {
  background-color: $primary !important;
}

.bg-success {
  background-color: $success !important;
}

.bg-info {
  background-color: $info !important;
}

.bg-warning {
  background-color: $warning !important;
}

.bg-danger {
  background-color: $danger !important;
}

.bg-muted {
  background-color: $muted !important;
}

.bg-white {
  background-color: $white !important;
}


//Text Color
.text-white {
  color: $white !important;
}

.text-danger {
  color: $danger !important;
}

.text-muted {
  color: $muted !important;
}

.text-primary {
  color: $primary !important;
}

.text-warning {
  color: $warning !important;
}

.text-success {
  color: $success !important;
}

.text-info {
  color: $info !important;
}

.text-dark {
  color: $dark !important;
}


//Badge
.badge-default {
  background-color: $light;
  color: $dark;
}

.badge-primary {
  background-color: $primary;
}

.badge-success {
  background-color: $success;
}

.badge-info {
  background-color: $info;
}

.badge-warning {
  background-color: $warning;
}

.badge-danger {
  background-color: $danger;
}

.badge-dark {
  background-color: $dark;
}

/* Navs & Tabs */
.nav-pills .nav-item.show .nav-link, .nav-pills .nav-link.active {
  background-color: $primary;
}

.nav-pills > .active > a > .badge {
  color: $primary;
}

/* List Group */
.list-group-item.active {
  background-color: $lightgray;
  border-color: $lightgray;
  color: $lightdark4;
  z-index: 2;
  &:hover {
    background-color: $lightgray;
    border-color: $lightgray;
    color: $lightdark4;
    z-index: 2;
    .list-group-item-text {
      color: $primary;
    }
  }
  &:focus {
    background-color: $lightgray;
    border-color: $lightgray;
    color: $lightdark4;
    z-index: 2;
    .list-group-item-text {
      color: $primary;
    }
  }
  .list-group-item-text {
    color: $primary;
  }
}

.list-group-item {
  border-radius: 0;
  padding: 12px 20px;
  border: 1px solid fade($dark,6%);
  &:first-child {
    border-radius: 0;
    padding: 12px 20px;
  }
  &:last-child {
    border-radius: 0;
    padding: 12px 20px;
  }
}

.list-group-item-heading {
  font-weight: 300;
}

.list-group-item.active > .badge {
  color: $primary;
}


// Popover
.popover-header {
  margin-top: 0;
}

// Blockquote
.blockquote {
  font-size: 1.05rem;
}