/*-------------------------------------------------*\
    Z-INDEX

    Use this file to store any z-index rules.
    Keeping them all in one place makes it easier
    to keep track of various elements in the stack.
\*-------------------------------------------------*/
.sites-grid {

    &__item-image {

        &:after {
            z-index: 5;
        }
    }

    &__item-image-inner {
        z-index: 10;
    }
}
