/*-------------------------------------------------*\
    ELEMENTS

    Use this file to assign styles to HTML elements,
    e.g. html, body, p, a, etc.

    Do not abuse this file. It's not a dumping
    ground for rules you can't be bothered to
    create a proper component for!
\*-------------------------------------------------*/

body {

    /*------------------------------------*\
        PRINT BREAKPOINT
        Print current breakpoint key as a
        pseudo of body. This can then be
        used in JS to determine the
        current breakpoint.
    \*------------------------------------*/
    &:before {
        display: none;
        speak: none;

        @include media-breakpoint-only("xs") {
            content: "xs";
        }

        @include media-breakpoint-only("sm") {
            content: "sm";
        }

        @include media-breakpoint-only("md") {
            content: "md";
        }

        @include media-breakpoint-only("lg") {
            content: "lg";
        }

        @include media-breakpoint-only("xl") {
            content: "xl";
        }
    }
}

a[href^="mailto"] {
    @include word-wrap();
}
