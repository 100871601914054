.sites-grid {
    $self: &;

    &__item {
        @extend %transition;

        display: flex;
        flex-direction: column;
        height: 100%;
        border-radius: $border-radius;
        text-decoration: none;
        overflow: hidden;

        @include hover() {
            transform: translateY(-4px);
            box-shadow: 0 4px 20px 0 rgba($black, 0.1);
            text-decoration: none;
        }
    }

    &__item-image {
        position: relative;
        padding-bottom: 65%;
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;

        &:after {
            @extend %transition;

            content: "";
            background: rgba($primary, 0.8);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            // has z-index

            #{ $self }__item:active &,
            #{ $self }__item:hover & {
                opacity: 1;
            }
        }
    }

    &__item-image-inner {
        @extend %transition;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        opacity: 0;
        // has z-index

        #{ $self }__item:active &,
        #{ $self }__item:hover & {
            opacity: 1;
        }
    }

    &__item-body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 5px 20px;
        background: $white;
    }

    &__item-title {
        margin-bottom: 5px;
        color: $body-color;
        font-weight: $font-weight-normal;

        @include font-size(20px, true, $line-height-sm);
    }

    &__item-set-count {
        color: $lightdark7;
        text-transform: uppercase;

        @include font-size(12px, true);
    }

    &__view-btn {
        text-transform: uppercase;
        padding: 10px 15px;
        border: 1px solid $white;
        border-radius: $border-radius;

        @include font-size(14px, true, 1);
    }
}
