.screenshot-thumb {
    $self: &;

    position: relative;
    display: block;
    background-color: $white;
    background-size: cover;
    background-position: center top;
    background-repeat: none;
    padding-bottom: 75%;

    &[data-status="complete"] {
        background-size: cover;
        background-position: center center;
    }

    &__inner {
        display: none;

        #{ $self }[data-status="pending"] & {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            & > i {
                color: #bababa;
                font-size: 32px;
            }
        }
    }
}
