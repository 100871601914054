.icon-buttons {

    &__link {
        @extend %transition;

        -webkit-border-radius: 50%;
        background: $light;
        border-radius: 50%;
        color: lighten($lightdark7, 15%);
        display: inline-block;
        height: 30px;
        line-height: 30px;
        text-align: center;
        width: 30px;

        &--primary {
            background: mix($white, $primary, 75%);
            color: $primary;
        }
    }
}
