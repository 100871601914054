/* ==============
  Pagination
===================*/
.page-link {
  color: $primary;
}
.page-item.active .page-link {
  background-color: $primary;
  border-color: $primary;
}
.page-link:focus, .page-link:hover {
  color: $dark;
  background-color: $light;
}