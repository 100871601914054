/*-------------------------------------------*\
    GRID

    A helper to create a basic grid using
    CSS Grid, with a Flexbox fallback for
    IE
\*-------------------------------------------*/

@mixin grid($columns: 4, $gap: $grid-gutter-width) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -#{ $gap/2 };
    margin-right: -#{ $gap/2 };

    > * {
        width: #{ 100%/$columns };
        padding-left: #{ $gap/2 };
        padding-right: #{ $gap/2 };
        padding-bottom: $gap;
    }

    @supports(display: grid) {
        display: grid;
        grid-template-columns: repeat(#{ $columns }, 1fr);
        grid-gap: #{ $gap };
        margin-left: 0;
        margin-right: 0;

        > * {
            width: auto;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
        }
    }
}
