.download-page {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 30px;
    text-align: center;

    &__panel {
        background: $white;
        padding: 20px;
        border-radius: $border-radius;
        width: 100%;
        max-width: 400px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    }

    &__logo {
        margin-bottom: 30px;
    }

    &__site-name {
        color: $primary;
        margin-bottom: 0;

        @include font-size(28px, true, $line-height-sm);
    }

    &__set-name {
        color: $secondary;
        margin-top: 0;

        @include font-size(18px, true, $line-height-sm);
    }

    &__download-btn {

        @include media-breakpoint-only(xs) {
            display: block;

            & + & {
                margin-top: 10px;
            }
        }
    }

    &__link-expiry_msg {
        margin-top: 15px;

        @include font-size(12px, true, 1.2);
    }
}
