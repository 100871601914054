.error-page {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 $spacer;
    height: 100%;
    text-align: center;
    max-width: 460px;
    color: $white;

    @include media-breakpoint-up(sm) {
        text-align: left;
    }

    &__code {
        width: 100%;

        @include font-size(100px, true, 1);

        @include media-breakpoint-up(sm) {
            @include font-size(140px, true, 1);
        }
    }

    &__message {
        width: 100%;

        @include font-size(22px, true, $line-height-sm);

        @include media-breakpoint-up(sm) {
            @include font-size(28px, true, $line-height-sm);
        }
    }
}
