/*-------------------------------------------------*\
    FONTS

    Use this file to declare any font-related
    declarations.

    For example, @font-face rules or @imports
    for fonts hosted elsewhere.
\*-------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Arimo:400,700');
