/*------------------------------------*\
    FONT SIZE

    This mixin will generate a REM unit for your chosen font size. It will also add
    the usual pixel unit for a fallback for browsers that don't support REMS.

    This can also calculate a line height for you based on your base line-height ratio.

    You can override the base line-height ratio if needed too.

    Usage example:

    With line-height:

    body {

        @include font-size(16px, true);
    }

    Without line-height:

    body {

        @include font-size(16px);
    }

\*------------------------------------*/

@mixin font-size($size, $calculate-line-height: false, $line-height-ratio: $line-height-base) {

    font-size: $size;
    font-size: ($size / $root-font-size)#{"rem"};

    @if($calculate-line-height == true) {
        line-height: ($size * $line-height-ratio);
        line-height: (($size / $root-font-size) * $line-height-ratio)#{"rem"};
    }

}
