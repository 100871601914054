.modal {

    @include media-breakpoint-up(sm) {
        padding-left: 1.75rem !important;
        padding-right: 1.75rem !important;
    }

    &--screenshot {

        .modal-body {
            padding: 0;
        }
    }
}

.modal-content {
    overflow: hidden;
}

.modal-title {
    margin: 0;
}
