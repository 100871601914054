.btn {

    &-disabled {
        opacity: 0.5;
    }

    &-warning {

        @include hover() {
            color: $white;
        }
    }
}
