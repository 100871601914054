.calendar {

    .fc-event {
        @extend %transition;

        cursor: pointer;

        @include hover() {
            background: lighten($primary, 1%) !important;
        }
    }

    .fc-day-number {
        margin-right: 4px;
    }
}
